<template>
  <v-flex :class="{ 'px-3': $vuetify.breakpoint.smAndUp }" xs12>
    <v-flex style="scale: 1" xs12>
      <!-- <ModalFindStore
        ref="modalFindStore"
        :saveStoreLocation="saveStoreLocation"
      /> -->
      <v-flex xs12>
        <v-card
          color="#f2f2f2"
          style="border-radius: 6px; min-height: 90vh;"
          class="fonte expande-horizontal wrap"
        >
          <div style="min-width: 100%;" class="store-layout ">
            <div
              style="z-index: 300;"
              class="expande-horizontal wrap centraliza profile-container"
            >
              <img
                style="z-index: 300;"
                @click.prevent="openUploadProfileContainer"
                class="store-profile"
                :src="profileImage"
              />
              <div style="display: flex; flex-direction: column;">
                <span
                  class="black--text fonteMini mt-2"
                  style="margin-left: -50px; width: 100%;white-space: nowrap;"
                >
                  <v-icon size="18" :color="$theme.primary"
                    >mdi-storefront</v-icon
                  >
                  {{ profile.nome }}</span
                >
                <span class="white--text fonteMini grey--text">{{
                  profile.cnpj
                }}</span>
              </div>
            </div>
          </div>
          <v-flex class="pb-1" xs12>
            <v-tabs
              centered
              class="mb-3 mt-3 fonte"
              :color="$theme.primary"
              v-model="tabs"
            >
              <v-tab>Dados da empresa</v-tab>
              <v-tab>Assinatura</v-tab>
            </v-tabs>

            <div v-if="tabs === 0" class="px-3 pb-1 expande-horizontal column">
              <v-text-field
                filled
                label="Nome"
                :color="$theme.primary"
                dense
                flat
                v-model="profile.nome"
              ></v-text-field>
              <v-text-field
                filled
                label="bio"
                :color="$theme.primary"
                dense
                flat
                v-model="profile.descricao"
              ></v-text-field>
              <v-text-field
                filled
                label="Telefone"
                :color="$theme.primary"
                dense
                flat
                v-model="profile.phone"
              ></v-text-field>
              <v-textarea
                filled
                height="100"
                label="Endereço completo"
                :color="$theme.primary"
                dense
                flat
                v-model="default_address_store.formatted_address"
              ></v-textarea>
              <v-flex xs12>
                <div class="expande-horizontal centraliza">
                  <v-btn
                    @click="saveProfile"
                    small
                    rounded
                    :color="$theme.third"
                    dark
                    class="elevation-0"
                    >Salvar</v-btn
                  >
                </div>
              </v-flex>
            </div>
            <div
              style="min-height: 390px; max-height: 390px; overflow: auto;"
              v-if="tabs === 1"
              class="px-3 pb-1 expande-horizontal wrap"
            >
              <v-flex xs12>
                <div class="fonte expande-horizontal centraliza wrap">
                  <v-flex xs12>
                    <v-list color="transparent" nav dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <h3
                              :style="`color: ${$theme.primary}`"
                              class="fonte"
                            >
                              Plano {{ subscription.plan_id.name }}
                            </h3>
                          </v-list-item-title>
                          <span class="fonte fonteMini grey--text"
                            >Próxima cobrança:
                            {{
                              $moment(
                                subscription.creation_of_next_charge
                              ).format("DD/MM/YYYY")
                            }}</span
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn x-small rounded color="orange"
                            >Ver Assinatura
                            <v-icon size="17">mdi-crown-outline</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </div>
                <div class="centraliza expande-horizontal wrap">
                  <v-flex xs12>
                    <v-list
                      color="transparent"
                      dense
                      class="pa-0 ma-0 fonte expande-horizontal"
                    >
                      <v-list-item
                        style="background: #e0e0e0;"
                        class="item-list-charge mb-3"
                        v-for="charge in charges"
                        :key="charge._id"
                      >
                        <v-avatar class="mr-3" :color="$theme.primary">
                          <v-icon color="#333">mdi-receipt-text-outline</v-icon>
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="black--text font-weight-bold"
                          >
                            {{ $helper.formataSaldo(charge.value / 1000) }}
                          </v-list-item-title>
                          <v-list-item-title class="black--text">
                            Cobrado
                            {{
                              $moment(charge.charge_fire).format("DD/MM/YYYY")
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text">
                            Situação: {{ charge.current_status }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            icon
                            text
                            color="grey"
                            style="opacity: 0.8"
                            class="font-weight-bold"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </div>
              </v-flex>
            </div>
          </v-flex>
        </v-card>
        <div class="mt-3 fonte expande-horizontal">
          <div class="expande-horizontal">
            <input
              id="cover"
              label="Faça o upload da foto de capa"
              type="file"
              ref="cover_upload"
              @change="preUploadCoverImage"
            />
          </div>
          <div>
            <input
              id="image_profile"
              type="file"
              ref="image_profile_upload"
              @change="preUploadProfileImage"
            />
          </div>
        </div>
      </v-flex>
    </v-flex>
    <v-dialog
      fullscreen
      transition="slide-x-transition"
      v-model="cropCoverImage"
    >
      <v-card
        dark
        class="expande-horizontal wrap centraliza"
        style="min-height: 100vh;"
      >
        <v-flex xs12>
          <v-list class="pa-0 ma-0 fonte">
            <v-list-item class="pr-6" @click="cropCoverImage = false">
              <v-avatar class="mr-3" :color="$theme.secondary" icon>
                <v-icon color="#fff">mdi-arrow-left</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Ajuste a foto de capa
                </v-list-item-title>
                <v-list-item-subtitle
                  >será considerado apenas o que estiver
                  selecionado</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="uploadCoverImage" :color="$theme.secondary" dark
                  >Salvar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <div class="pl-6 pr-6 expande-horizontal wrap">
          <v-flex xs12>
            <div style="width: 100%; height: 80vh;">
              <vue-cropper
                style="border-radius: 6px;"
                ref="cropper10"
                :img="cropCoverImageData"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :fixed="fixed"
                :fixed-number="fixedNumber"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :fixed-box="option.fixedBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :auto-crop-width="option.autoCropWidth"
                :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox"
                @real-time="realTime"
                :high="option.high"
                @img-load="imgLoad"
                mode="cover"
                :max-img-size="option.max"
                @crop-moving="cropMoving"
                @crop-complete="uploadProfileImage"
              ></vue-cropper>
            </div>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      transition="slide-x-transition"
      v-model="cropProfileImage"
    >
      <v-card
        dark
        class="expande-horizontal wrap centraliza"
        style="min-height: 100vh;"
      >
        <v-flex xs12>
          <v-list class="pa-0 ma-0 fonte">
            <v-list-item class="pr-6" @click="cropProfileImage = false">
              <v-avatar class="mr-3" :color="$theme.secondary" icon>
                <v-icon color="#fff">mdi-arrow-left</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Ajuste a foto de perfil
                </v-list-item-title>
                <v-list-item-subtitle
                  >será considerado apenas o que estiver
                  selecionado</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click="uploadProfileImage"
                  :color="$theme.secondary"
                  dark
                  >Salvar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <div class="pl-6 pr-6 expande-horizontal wrap">
          <v-flex xs12>
            <div style="width: 100%; height: 80vh;">
              <vue-cropper
                style="border-radius: 6px;"
                ref="cropper20"
                :img="cropProfileImageData"
                :output-size="option2.size"
                :output-type="option2.outputType"
                :info="true"
                :full="option2.full"
                :fixed="fixed2"
                :fixed-number="fixedNumber2"
                :can-move="option2.canMove"
                :can-move-box="option2.canMoveBox"
                :fixed-box="option2.fixedBox"
                :original="option2.original"
                :auto-crop="option2.autoCrop"
                :auto-crop-width="option2.autoCropWidth"
                :auto-crop-height="option2.autoCropHeight"
                :center-box="option2.centerBox"
                @real-time="realTime"
                :high="option2.high"
                @img-load="imgLoad"
                mode="cover"
                :max-img-size="option2.max"
                @crop-moving="cropMoving"
                @crop-complete="uploadProfileImage"
              ></vue-cropper>
            </div>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import { EventBus } from "@/main";
import ListAreas from "./componentesDeliveryConfig/ListAreas.vue";
import ModalCreateArea from "./componentesDeliveryConfig/ModalCreateArea.vue";
import ModalFindStore from "./componentesDeliveryConfig/ModalFindStore.vue";
import { getStorage, ref, getDownloadURL, uploadBytes, uploadBytesResumable, deleteObject  } from "firebase/storage";

export default {
  props: ["local"],
  data() {
    return {
      tabs: 0,
      moment: moment,
      coverImage: "https://via.placeholder.com/1600x400",
      profileImage: "https://via.placeholder.com/512x512",
      coverProgress: 0,
      profileProgress: 0,
      cropCoverImage: false,
      cropCoverImageData: "",
      cropProfileImage: false,
      cropProfileImageData: "",
      option: {
        size: 256,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 512,
        autoCropHeight: 512,
        centerBox: false,
        high: false,
        max: 99999
      },
      option2: {
        size: 60,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 120,
        autoCropHeight: 120,
        centerBox: false,
        high: false,
        max: 9999
      },
      fixed: true,
      fixedNumber: [16, 4],
      fixed2: true,
      fixedNumber2: [1, 1],
      profile: {},
      charges: {},
      subscription: {},
      center: [-47.8815, -15.7942],
      zoom: 10,
      areas: [],
      areasGeojson: {
        type: "FeatureCollection",
        features: []
      },
      addAreaDialog: false,
      editAreaDialog: false,
      selectedArea: null,
      map: null,
      draw: null,
      default_address_store: {}
    };
  },
  components: {
    VueCropper,
    ListAreas,
    ModalCreateArea,
    ModalFindStore
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    daysLeft() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      const days = moment(expires).diff(moment(), "days");
      return days;
    },
    dateExpire() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      return moment(expires).format("DD/MM/YYYY [às] HH:mm");
    }
  },
  filters: {
    filterStatusSubscription(val) {
      switch (val) {
        case "active":
          return "ativa";
        default:
          return "";
      }
    }
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    saveLocal() {
      EventBus.$emit("saveLocal", this.local);
    },
    openUploadProfileContainer() {
      this.$refs.image_profile_upload.click();
    },
    openUploadCoverContainer() {
      this.$refs.cover_upload.click();
    },
    cropMoving() {},
    cropImg() {},
    imgLoad() {},
    realTime() {},
    preUploadCoverImage() {
      const input = this.$refs.cover_upload;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropCoverImageData = e.target.result;
          this.cropCoverImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    preUploadProfileImage() {
      const input = this.$refs.image_profile_upload;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropProfileImageData = e.target.result;
          this.cropProfileImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    base64ToBlob(base64, contentType) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    },
    async uploadCoverImage() {
      this.$refs.cropper10.getCropBlob(async data => {
        this.coverImage = data;
        const file = new Image();
        file.src = data;
        const storageRef = this.$storage.ref(
          `cover_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        const task = storageRef.put(data);
        task.on(
          "state_changed",
          snapshot => {
            this.coverProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            this.coverImage = await task.snapshot.ref.getDownloadURL();
            this.saveProfileImage("coverImage");
          }
        );
      });
    },
    async uploadProfileImage() {
      const self = this;
      this.$refs.cropper20.getCropBlob(async data => {
        var metadata = {
          contentType: data.type
        };
        const storage = getStorage();
        const storageRef = ref(storage);
        const imageRef = ref(
          storageRef,
          `store_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        const task = uploadBytesResumable(imageRef, data, metadata);
        task.on(
          "state_changed",
          snapshot => {
            self.profileProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            self.profileImage = await getDownloadURL(task.snapshot.ref);
            self.saveProfileImage("profileImage");
          }
        );
      });
    },
    async getMyProfile() {
      this.loading = true;
      this.$run("marketplaces/show-my-profile")
        .then(res => {
          if (res.data.profile.coverImage) {
            this.coverImage = res.data.profile.coverImage;
          }
          if (res.data.profile.profileImage) {
            this.profileImage = res.data.profile.profileImage;
          }
          this.subscription = res.data.subscription;
          this.profile = res.data.profile;
          this.charges = res.data.charges;
          this.default_address_store = res.data.profile.address || {};
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    async saveProfileImage(type) {
      this.loading = true;
      let data = {};
      data[type] = this[type];
      this.$run("marketplaces/set-profile-image", data)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    saveProfile() {
      this.saveStoreLocation(this.default_address_store);
      this.loading = true;
      this.$run("marketplaces/set-store-info", {
        nome: this.profile.nome,
        descricao: this.profile.descricao,
        phone: this.profile.phone
      })
        .then(() => {
          this.loading = false;
          this.createGlobalMessage({
            type: "success",
            message: "Atualizado 🏡😁",
            timeout: 3000
          });
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    saveStoreLocation(address) {
      this.$run("marketplaces/set-default-address-store", {
        address: address
      })
        .then(() => {
          this.getDeliveryConfig();
        })
        .catch(e => {});
    },
    getStoreLocation() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-default-address-store")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.default_address_store = res.data;
        })
        .catch(e => {});
    },
    openModalFindStore() {
      this.$refs.modalFindStore.open();
    },
    openModalCreateAreaByKm() {
      {
        const area_type = "by_km";
        this.$refs.modalCreateArea.openCreateArea(false, area_type);
      }
    },
    changeArea(event) {
      switch (event.type) {
        case "draw.create":
          {
            const area_type = "by_area";
            this.$refs.modalCreateArea.openCreateArea(event, area_type);
          }
          break;
        case "draw.update":
          this.updateArea(event);
          break;
        case "draw.delete":
          this.deleteArea(event);
          break;
        default:
          break;
      }
    },
    confirmRemoveArea(index) {
      this.createConfirmAction({
        icon: "mdi-warning",
        color: "yellow darken-3",
        message: "Deseja remover esta área??",
        action: this.removeArea,
        action_value: index,
        isScoped: true
      });
    },
    removeArea(index) {
      let new_areas = [];
      this.areas.map((a, i) => {
        if (i !== index) {
          new_areas.push(a);
        }
      });
      this.areas = new_areas;
      this.saveDeliveryTax(new_areas);
    },
    saveArea(area) {
      this.areas.push(area);
      this.$refs.modalCreateArea.close();
      this.saveDeliveryTax(this.areas);
    },
    updateAreas(area) {},
    updateArea(area) {},
    deleteArea(area) {},
    saveDeliveryTax(data) {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/set-config-delivery", {
        range_delivery_tax: data
      })
        .then(() => {
          this.$store.dispatch("disableLoading");
          this.createGlobalMessage({
            timeout: 6000,
            type: "sucess",
            message: "Configurações de delivery salvas!"
          });
        })
        .catch(e => {});
    },
    selectFeature(feature) {
      if (this.map.getLayer("my-layer") !== undefined) {
        this.map.removeLayer("my-layer");
      }
      if (this.map.getSource("my-data") !== undefined) {
        this.map.removeSource("my-data");
      }

      this.map.addSource("my-data", {
        type: "geojson",
        data: { ...feature.area[0] }
      });
      this.map.addLayer({
        id: "my-layer",
        type: "fill",
        source: "my-data",
        paint: {
          "fill-color": "#8A2BE2",
          "fill-opacity": 0.4
        }
      });
      setTimeout(() => {
        // var bounds = new mapboxgl.LngLatBounds();
        // Encontrar as coordenadas extremas
        const coordinates = feature.area[0].geometry.coordinates[0];
        const bounds = coordinates.reduce(function(bounds, coord) {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        // Ajustar o mapa para exibir as coordenadas extremas
        this.map.fitBounds(bounds, { padding: 120 });
        // var source = this.map.getSource("my-data");
        // var bounds = source.getBounds();
        // const geometryLength =
        //   feature.area[0].geometry.coordinates[
        //     feature.area[0].geometry.coordinates[0]
        //   ];
        // console.log("geometry", feature.area[0].geometry.coordinates[0][0]);
        // console.log("geometry len", geometryLength);
        // bounds.extend(feature.area[0].geometry.coordinates[0]);

        // Calcula o centro da caixa delimitadora
        // var center = bounds.getCenter();
        // console.log("center", center);
        // this.map.fitBounds(bounds, { padding: 20 });

        // Define o zoom certo para visualizar toda a fonte de dados
        // var zoom = this.map.getZoomForBounds(bounds);
        // console.log("zoom", zoom);

        // Move o mapa para o centro da fonte de dados com o zoom certo
        // this.map.flyTo({
        //   center: center,
        //   zoom: zoom
        // });
      }, 300);
    },
    getDeliveryConfig() {
      this.map = null;
      this.loading = true;
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-delivery")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.areas = res.data.range_delivery_tax;
          const hasAddress = res.data.address.geometry ? true : false;
          const defaultAddress = hasAddress
            ? [
                res.data.address.geometry.location.lng,
                res.data.address.geometry.location.lat
              ]
            : [-51.084535, -0.002955];
          if (hasAddress) {
            this.default_address_store = res.data.address;
          }
          setTimeout(() => {
            mapboxgl.accessToken =
              "pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg";
            this.map = new mapboxgl.Map({
              container: "map", // container ID
              // style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              center: defaultAddress,
              zoom: 16 // starting zoom
            });
            this.draw = new MapboxDraw({
              displayControlsDefault: false,
              // Select which mapbox-gl-draw control buttons to add to the map.
              controls: {
                polygon: true,
                line: false,
                // circle: true,
                trash: true
              },
              // Set mapbox-gl-draw to draw by default.
              // The user does not have to click the polygon control button first.
              defaultMode: "draw_polygon"
            });
            const marker = new mapboxgl.Marker()
              .setLngLat(defaultAddress)
              .addTo(this.map);

            // const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            //   `<v-list-item>
            //     <v-list-item-avatar>
            //       <v-icon color="#fff">mdi-map-marker</v-icon>
            //     </v-list-item-avatar>
            //     v-list-item-content>
            //       <v-list-item-title class="font-weight-bold">
            //         ${this.default_address_store.formatted_address}
            //       </v-list-item-title>
            //       <v-list-item-subtitle>
            //         ${this.default_address_store.geometry.location.lat}, ${this.default_address_store.geometry.location.lng}
            //       </v-list-item-subtitle>
            //       <v-list-item-action>
            //         <v-btn @click="openModalFindStore" color="#fff" dark>
            //           <v-icon>mdi-edit</v-icon>
            //         </v-btn>
            //       </v-list-item-action>
            //    </v-list-item>
            //   `
            // );

            // marker.setPopup(popup);
            marker.togglePopup();
            // this.draw.changeMode("draw_circle");
            // const self = this;
            this.map.addControl(this.draw);
            // this.map.on("load", function() {
            //   self.draw.changeMode("draw_circle");
            // });
            this.map.on("draw.create", this.changeArea);
            this.map.on("draw.delete", this.changeArea);
            this.map.on("draw.update", this.changeArea);
            this.loading = false;
          }, 2000);
        })
        .catch(e => {
          setTimeout(() => {
            mapboxgl.accessToken =
              "pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg";
            this.map = new mapboxgl.Map({
              container: "map", // container ID
              // style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              style: "mapbox://styles/ptklorran/clf6pheg000ck01p9njsdb8wx",
              center: [-51.084535, -0.002955],
              zoom: 16 // starting zoom
            });
            this.draw = new MapboxDraw({
              displayControlsDefault: false,
              // Select which mapbox-gl-draw control buttons to add to the map.
              controls: {
                polygon: true,
                line: false,
                // circle: true,
                trash: true
              },
              // Set mapbox-gl-draw to draw by default.
              // The user does not have to click the polygon control button first.
              defaultMode: "draw_polygon"
            });
            const marker = new mapboxgl.Marker()
              .setLngLat([-51.084535, -0.002955])
              .addTo(this.map);

            // const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            //   `
            //     <div style="display: flex; flex-wrap: nowrap;">
            //       <div>
            //       </div>
            //     <v-list-item>
            //       <v-list-item-avatar>
            //         <v-icon color="#fff">mdi-map-marker</v-icon>
            //       </v-list-item-avatar>
            //       v-list-item-content>
            //         <v-list-item-title class="font-weight-bold">
            //           ${this.default_address_store.formatted_address}
            //         </v-list-item-title>
            //         <v-list-item-action>
            //           <v-btn @click="openModalFindStore" color="#fff" dark>
            //             <v-icon>mdi-edit</v-icon>
            //           </v-btn>
            //         </v-list-item-action>
            //     </v-list-item>
            //    </div>
            //   `
            // );

            // marker.setPopup(popup);
            marker.togglePopup();
            // this.draw.changeMode("draw_circle");
            // const self = this;
            // this.map.addControl(this.draw); //descomenda para que consiga desenhar na tela.
            // this.map.on("load", function() {
            //   self.draw.changeMode("draw_circle");
            // });
            this.map.on("draw.create", this.changeArea);
            this.map.on("draw.delete", this.changeArea);
            this.map.on("draw.update", this.changeArea);
            this.loading = false;
          }, 2000);
        });
    }
  },
  mounted() {
    this.getMyProfile();
    const script1 = document.createElement("script");
    script1.src =
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.js";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://unpkg.com/@turf/turf@6/turf.min.js";
    script2.async = true;
    document.head.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = "https://api.mapbox.com/mapbox-gl-js/v2.13.0/mapbox-gl.js";
    script3.async = true;
    document.head.appendChild(script3);

    const link1 = document.createElement("link");
    link1.rel = "stylesheet";
    link1.href =
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.css";
    document.head.appendChild(link1);

    const link2 = document.createElement("link");
    link2.rel = "stylesheet";
    link2.href = "https://api.mapbox.com/mapbox-gl-js/v2.13.0/mapbox-gl.css";
    document.head.appendChild(link2);
    this.getDeliveryConfig();
  }
};
</script>

<style>
.store-layout {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 200px !important;
  background: #f2f2f2;
  cursor: pointer;
  margin-bottom: 15px;
}

.store-cover {
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.profile-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.store-profile {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 6px;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item-list-charge {
  background: #f2f2f2;
  border-radius: 6px;
}
</style>
